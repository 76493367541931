import React, { useRef, useEffect } from "react";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "utils/PerformanceTracker";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkspace } from "ee/actions/workspaceActions";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import { EntityExplorerWrapper } from "pages/Editor/Explorer/Common/EntityExplorerWrapper";
import {
  ActionEntityContextMenuItemsEnum,
  FilesContextProvider,
} from "pages/Editor/Explorer/Files/FilesContextProvider";
import { ActionParentEntityType } from "ee/entities/Engine/actionHelpers";
import { getCurrentPackageId } from "ee/selectors/packageSelectors";
import { getCurrentModule } from "ee/selectors/modulesSelector";
import Files from "pages/Editor/Explorer/Files";
import { selectFilesForPackageExplorer } from "ee/selectors/entitiesSelector";
import { MODULE_TYPE } from "ee/constants/ModuleConstants";
import { getShowModuleReference } from "ee/selectors/moduleFeatureSelectors";

function PackageEntityExplorer() {
  const dispatch = useDispatch();
  PerformanceTracker.startTracking(PerformanceTransactionName.ENTITY_EXPLORER);
  useEffect(() => {
    PerformanceTracker.stopTracking();
  });
  const explorerRef = useRef<HTMLDivElement | null>(null);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const packageId = useSelector(getCurrentPackageId) || "";
  const module = useSelector(getCurrentModule);
  const showModuleReference = useSelector(getShowModuleReference);

  useEffect(() => {
    dispatch(fetchWorkspace(currentWorkspaceId));
  }, [currentWorkspaceId]);

  if (!Boolean(module)) return null;

  return (
    <EntityExplorerWrapper explorerRef={explorerRef} isActive>
      {module && module.type === MODULE_TYPE.JS && (
        <FilesContextProvider
          canCreateActions={!showModuleReference}
          editorId={packageId}
          menuItems={[
            ActionEntityContextMenuItemsEnum.RENAME,
            ActionEntityContextMenuItemsEnum.DELETE,
          ]}
          parentEntityId={module.id}
          parentEntityType={ActionParentEntityType.MODULE}
          selectFilesForExplorer={selectFilesForPackageExplorer}
          showModules={false}
          // TODO: Add workflows support when it is ready
          showWorkflows={false}
        >
          <Files />
        </FilesContextProvider>
      )}
    </EntityExplorerWrapper>
  );
}

PackageEntityExplorer.displayName = "PackageEntityExplorer";

export default PackageEntityExplorer;
